import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="frontpage" className="page_container">
          <div className="headerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <p>
                      <a title="Logo" className="siteLogo" href="/">
                        <img alt="Migraleve" src="/images/logo.jpg" />
                      </a>
                    </p>
                  </div>
                </div>
                <div className="trigger">
                  {" "}
                  <a>
                    <img src="/sites/migraleve.co.uk/files/images/menu_arrow.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper1">
              <div id="block-menu-primary-links" className="block block-menu">
                <div className="content">
                  <ul className="menu">
                    <li className="leaf first home">
                      <a href="/home" title="Home">
                        <img src="/sites/migraleve.co.uk/files/images/home.png" />
                      </a>
                    </li>
                    <li className="expanded active-trail aboutmigraines">
                      <a
                        href="/about-migraine"
                        title="About Migraines"
                        className="active about-migraine"
                      >
                        ABOUT MIGRAINES
                      </a>
                      <ul className="menu">
                        <li className="leaf first migrainesymptoms">
                          <a
                            href="/migraine-symptoms"
                            title="Migraine Types & Symptoms"
                            className="active"
                          >
                            Migraine Types & Symptoms
                          </a>
                        </li>
                        <li className="leaf potentialmigrainecauses">
                          <a
                            href="/migraine-causes"
                            title="Potential Migraine Causes"
                          >
                            Potential Migraine Causes
                          </a>
                        </li>
                        <li className="leaf last whatcanyoudoaboutit">
                          <a
                            href="/migraine-treatment-and-prevention"
                            title="Treatment and Relief"
                          >
                            Treatment and Relief
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="leaf ourproducts">
                      <a href="/migraine-relief-products" title="Our Products">
                        OUR
                        <br /> PRODUCTS
                      </a>
                    </li>
                    <li className="leaf migrainemanagementtips">
                      <a href="/migraine-tips" title="Migraine Management Tips">
                        MIGRAINE MANAGEMENT TIPS
                      </a>
                    </li>
                    <li className="leaf migrainetriggerdiary">
                      <a
                        href="/migraine-trigger-diary"
                        title="Migraine Trigger Diary"
                      >
                        MIGRAINE TRIGGER DIARY
                      </a>
                    </li>
                    <li className="leaf last wheretobuy">
                      <a href="/where-to-buy" title="Where to buy">
                        WHERE TO BUY
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contentOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper">
              <div className="homeBannerWrapper">

                <div className="headingwrapper">
                  <div className="titlewrapper">
                    <h1>TYPES OF MIGRAINES
                      AND THEIR SYMPTOMS <br />
                    </h1>
                    <h4 className="subheading">
                      <strong>If you get migraines, then you'll know that a migraine is more than just a headache.</strong>
                      It's an intense and throbbing pain, which often occurs in one side of the head,
                      although it can be more generalised.
                    </h4>
                  </div>
                  <div className="imagewrapper">
                    <img src="/sites/migraleve.co.uk/files/images/migraine-symptoms.jpg" />
                  </div>
                </div>

                <div className="innerPageContentWrapper">
                  <div className="innerPageContent">
                    <div id="node-11" className="node">
                      <div className="content clear-block">
                        <p>
                          In adults, migraine attacks can be divided into several phases. Although not everyone goes through all of these.
                        </p>
                        <h2>Phases of a Migraine</h2>
                        <ol className="pointer withmargins">
                          <li><strong>Premonitory stage – </strong>During this stage people can feel a variety of physical and mental changes such as tiredness, craving certain types of foods, mood changes, feeling thirsty, neck stiffness and frequent yawning. These feelings can last up to a day.</li>
                          <li>
                            <strong>Aura – </strong>  Around 1/3 of people with migraines may go through this stage. Aura occurs due to a spontaneous, slow-moving wave that passes over the surface of the brain temporarily affecting the functioning of the parts it travels over. His associated symptoms depend on which parts of the brain are affected.
                          </li>
                          <li>
                            <strong>The Headache (main attack stage) – </strong>This stage involves head pain, which can be extremely severe. The headache is typically throbbing, and made worse by movement, light or sound. The headache is usually on one side of the head but can be on both sides, or all over the head. Sickness and vomiting can happen at this stage. This stage can last up to 3 days.
                          </li>
                          <li><strong>Resolution – </strong>Most attacks slowly fade away, but some stop suddenly. Sleep seems to help many people. Even an hour or two of sleep can be enough to end an attack.</li>
                          <li>
                            <strong>Recovery – </strong>This is the final stage of an attack which can best be described as a ‘hangover’ type feeling. This feeling can take days to disappear. Symptoms can often mirror the premonitory stage. For example, if a person lost their appetite at the beginning of the attack, they might be very hungry now.
                          </li>
                        </ol>

                        <h2> Types of Migraine</h2>
                        <p>
                          There are different types of migraine, but not everyone will have ‘typical’ migraine. Although, a migraine without aura is the most common, about 1 in 3 people who suffer from migraines may experience a migraine with aura.
                        </p>

                        <h1 className="content_title">Migraine Symptoms:</h1>
                        <div className="contentInnerWrapper">
                          <h2>Aura Symptoms:</h2>
                          <p>
                            People who have a migraine with aura can have the following symptoms
                            which develop over about 5 minutes and last for up to an hour.
                          </p>

                          <ul className="pointer">
                            <li>
                              Visual disturbances (blind spots, seeing coloured spots or lines, seeing flashing or flickering lights, seeing zig zag patterns, temporary blindness)
                            </li>
                            <li>
                              Numbness or tingling like pins and needles in parts of your body
                            </li>
                            <li>Muscle weakness</li>
                            <li>Feeling dizzy or off balance</li>
                          </ul>
                        </div>

                        <div className="contentInnerWrapper">
                          <h2>Common Migraine Symptoms:</h2>

                          <ul className="pointer">
                            <li>A headache that is usually on one side of your head. This is often a throbbing pain. It will worsen when you move, such as if you walk or climb the stairs. It is so severe that it means you can’t do your normal daily activities.</li>
                            <li>Feeling sick (nausea) or being sick (vomiting).</li>
                            <li>Being sensitive to light (photophobia), sound (phonophobia) and/or smells.</li>
                            <li>
                              Migraines usually last between 2 hours and 3 days, with some symptoms (such as feeling very tired) starting up to 2 days before the head pain starts and finishing after the headache stops.
                            </li>
                          </ul>
                          <p>
                            The information on this page Is not designed to aid in a diagnosis. If you are getting regular headaches or other symptoms that you suspect might be a migraine, please contact your GP.
                          </p>
                        </div>
                      </div>
                      <div className="clear-block">
                        <div className="meta"></div>
                      </div>
                    </div>
                  </div>
                  <div className="innerPagePromo">
                    <div id="block-block-5" className="block block-block">
                      <div className="content">
                        <div className="migrane_tips">
                          <div className="inner">
                            <img alt src="/images/ma.png" />
                            <div>
                              <h2>tips</h2>
                              <p>
                                Get some useful tips to help prevent a migraine
                                attack.
                              </p>
                              <a title="Tips" href="/tips">
                                Find out more
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="buying_migraleve">
                          <div className="inner">
                            <img alt src="/images/bm.png" />
                            <div>
                              <h2>
                                buying migraleve<sup>™</sup>
                              </h2>
                              <p>
                                Find your nearest stockist of Migraleve
                                products.
                              </p>
                              <a title="Where to buy" href="/where-to-buy">
                                Where to buy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="trigger_diaryBg">
                          <div className="inner">
                            <img alt src="/images/td.png" />
                            <div>
                              <h2>trigger diary</h2>
                              <p>
                                Want to find out what triggers your migraines?
                              </p>
                              <a
                                title="Try a trigger diary"
                                href="/trigger-diary"
                              >
                                Try a trigger diary
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="info-section">
                  <div className="row">
                    <div className="col">
                      <div className="thumbnail">
                        <img src="/sites/migraleve.co.uk/files/images/causes.jpg" />
                        <div className="content">
                          <h2 className="text-normal">
                            Potential Migraine Causes
                          </h2>
                          <p>
                            Read more about what can trigger a migraine.
                          </p>
                          <a
                            href="/migraine-causes"
                            className="learnmore btn-light"
                          >
                            Learn More{" "}
                            <span className="float-right"> > </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="thumbnail">
                        <img src="/sites/migraleve.co.uk/files/images/treatments.jpg" />
                        <div className="content">
                          <h2 className="text-normal">
                            Treatment and Relief
                          </h2>
                          <p>
                            Read more about how you can treat and relieve your migraine.
                          </p>
                          <a
                            href="/migraine-treatment-and-prevention"
                            className="learnmore btn-light"
                          >
                            Learn More{" "}
                            <span className="float-right"> > </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="thumbnail">
                        <img src="/sites/migraleve.co.uk/files/images/trigger_diary.jpg" />
                        <div className="content">
                          <h2 className="text-normal">
                            Migraine Trigger Diary
                          </h2>
                          <p>
                            Track your migraines to gain an understanding of your triggers.
                          </p>
                          <a
                            href="/migraine-trigger-diary"
                            className="learnmore btn-light"
                          >
                            Learn More{" "}
                            <span className="float-right"> > </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper">
              <div className="footerContent">
                <div
                  id="block-menu-menu-site-footer-menu"
                  className="block block-menu"
                >
                  <div className="content">
                    <ul className="menu">
                      <li className="leaf first privacypolicy">
                        <a href="/privacy-policy" title="Privacy policy">
                          Privacy policy
                        </a>
                      </li>
                      <li className="leaf legalnotice">
                        <a href="/legal-notice" title="Legal notice">
                          Legal notice
                        </a>
                      </li>
                      <li className="leaf contactus">
                        <a href="/contact-us" title="Contact us">
                          Contact us
                        </a>
                      </li>
                      <li className="leaf cookiepolicy">
                        <a href="/cookie-policy" title="Cookie Policy">
                          Cookie Policy
                        </a>
                      </li>
                      <li className="leaf modernslaveryactstatement">
                        <a
                          href="/sites/migraleve.co.uk/files/pdf/MSA_JnJ_Limited_May_2024.pdf"
                          target="_blank"
                        >
                          Modern Slavery Act Statement
                        </a>
                      </li>
                      <li className="leaf last cookiesettings">
                        <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                          Cookie Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="block-block-3" className="block block-block">
                  <div className="content">
                    <p>
                      <strong>
                        Always read the label. Ask your pharmacist for advice.
                      </strong>
                    </p>
                    <p>
                      MIGRALEVE<em>™</em> contains paracetamol, buclizine and
                      codeine. MIGRALEVE<em>™</em> Pink contains paracetamol,
                      codeine and buclizine. MIGRALEVE<em>™</em> Yellow contains
                      paracetamol and codeine.
                    </p>
                    <p>
                      Can cause addiction. For three days use only. This
                      medicine can make you feel sleepy. Do not drive while
                      taking this medicine until you know how it makes you feel.
                    </p>
                    <p>
                      This site is published by Johnson &amp; Johnson Limited
                      which is solely responsible for its contents. It is
                      intended for a UK audience. MIGRALEVE<em>™</em> is a
                      registered trademark.
                    </p>
                    <p>
                      This site is best viewed with the following web browsers:
                      Internet Explorer version 8, Firefox version 2+, Safari
                      3+, Chrome version 3+.
                    </p>
                    <p>
                      © Johnson &amp; Johnson Limited 2024. This site was last
                      updated on: 15<sup>th </sup>August 2024
                    </p>
                  </div>
                </div>
                <p>
                  <a
                    id="popup-trigger"
                    href="/sites/migraleve.co.uk/files/pop-up-text"
                    rel="lightmodal"
                  >
                    hide this
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
